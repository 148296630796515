@tailwind base;
@tailwind components;
@tailwind utilities;




/*-----------------------------------------------------------------------------------

    Template Name: Adstatz
    Template URI: 
    Description: This is Next js template
    Author: 
    Author URI: http://.com
    Version: 1.0

-----------------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------------

     CSS INDEX
    ===================
    1.Global CSS
    2.Common CSS
    3.Side bar CSS
    4.Footer Pgae CSS

-----------------------------------------------------------------------------------*/






:root {
  --bs-green: #34A853;
  --text-black: #1E2022;
  /*  */
  --text-gray: #A1A5B7;
  /*  */
  --body-clr: #F9FCFE;
  --border-clr: #c7ccd5;
  --Text-2: #5E6278;
  --linkclr: #1975FF;

}

* {

  box-sizing: border-box !important;
  line-height: 1.4;
}

body {
  box-sizing: border-box !important;
  padding: 0;
  margin: 0;
  background-color: var(--body-clr);
}

html {
  scroll-behavior: smooth;
}

/* Removeing input number arrow Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Removeing input number arrow : Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*----------------------------------------------------*/
/* 1.Global CSS */
/*----------------------------------------------------*/


.highcharts-tooltip > span {
  background: rgb(255 255 255 / 85%);
}


.h1,
h1 {
  font-size: 26px;
  font-weight: 700 !important;
  color: var(--text-black);
}

.h2,
h2 {
  font-size: 22px;
  font-weight: 700 !important;
  color: var(--text-black);
}

.h3,
h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--text-black);
}

.h4,
h4 {
  font-size: 18px;
  font-weight: 700;
  color: var(--text-black);
}

.h5,
h5 {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black);
}

.h6,
h6 {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-black);
}

.p,
p {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-gray);
}

/*----------------------------------------*/
/* 2.Common CSS */
/*----------------------------------------*/
.width-ainm {
  -webkit-transition: width 395ms cubic-bezier(0.4, 0, 0.6, 1) 50ms, margin 395ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 395ms cubic-bezier(0.4, 0, 0.6, 1) 50ms, margin 395ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}


.scrollbar-hidden {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;

}

/* custom scroll bar  */
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}



/* for sticky table */

.layout__content {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;

}

.table__wrapper {
  overflow-x: auto;
  position: relative;
  scrollbar-width: none;
}







/*pagination border removal  */
.m_326d024a {
  outline: none !important;
  border: none !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}


.apexcharts-toolbar {
  display: none !important;
}


/* multi slect */

:where([data-mantine-color-scheme='light']) .m_44da308b {
  background-color: #E8F6FF !important;
}

  

/* checkbox */

/* input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid #0070F3;
  border-radius: 2px;
  position: relative; 
}

input[type="checkbox"]:checked {
  background-color: #0070F3;
  border: 1px solid #0070F3;
}

input[type="checkbox"]:checked::before {
  content: "\2714";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

input[type="checkbox"]:not(.mantine-Checkbox-input) {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid #0070F3;
  border-radius: 2px;
  position: relative;
}

input[type="checkbox"]:not(.mantine-Checkbox-input):checked {
  background-color: #0070F3;
  border: 1px solid #0070F3;
}

input[type="checkbox"]:not(.mantine-Checkbox-input):checked::before {
  content: "\2714";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/* highcharts-credits */

.highcharts-credits{
  display: none !important;
}

/* timezone slected */
.custom-timezone-select select {
  border: none !important;
  outline: none !important;
  font-size: 12px !important;
  background: #f5f8fa !important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1) !important;
}





/* timezone slecter */

.css-13cymwt-control{
  background: #F5F8FA !important;
  border: none  !important;
  border-radius: 11px !important;
}


/*----------------------------------------*/
/* 3. Side Bar CSS */
/*----------------------------------------*/

/*----------------------------------------*/
/* 4. Footer CSS */
/*----------------------------------------*/


/*----------------------------------------*/
/* 5. Page CSS */
/*----------------------------------------*/

/*----------------------------------------*/
/* 6. Page CSS */
/*----------------------------------------*/

/*----------------------------------------*/
/* 7. Page CSS */
/*----------------------------------------*/

/*----------------------------------------*/
/* 8. Page CSS */
/*----------------------------------------*/


/*----------------------------------------*/
/*Responsive CSS */
/*----------------------------------------*/
@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {

  /* 404 page  */
  .Error404 {
    top: 30% !important;
  }
}

@media only screen and (max-width: 576px) {}

@media only screen and (max-width: 425px) {}

@media only screen and (max-width: 375px) {}

@media only screen and (max-width: 320px) {}

/*--------------------------------*/
/* .End CSS
/*--------------------------------*/